import React, { useState, useRef } from "react"
import PropTypes from "prop-types"

const Modal = (props) => {
  const modalBox = useRef()

  const closeModal = (e) => {
    let el = document.querySelector('.modal-help--wrapper')
    if(el.contains(e.target)){
      return false
    }
    else {
      props.closeModal()
    }
  }

  return (
    <div className={`modal-help open`} onClick={(e) => closeModal(e)}>
      <div className="modal-help--wrapper" ref={modalBox}>
        <div className={"modal-help--content"}>
        <button className="modal-help--close" onClick={() => props.closeModal()}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{height: '15px', width: '15px'}}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
          <div className={"modal-help--text"}>
          {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {}

export default Modal
