import React from "react"
import "./nomobile.scss"

const Nomobile = (props) => (
  <div className={'nomobile'}>
    <div className="nomobile-wrapper">
      <p>Sorry, we don't support mobile yet.</p>
    </div>
  </div>
)

export default Nomobile