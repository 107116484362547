import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Drawer from "../components/drawer"
import Signature from "../components/signature"
import SignatureProvider from "../components/SignatureProvider"

const IndexPage = () => {

  const og = "/assets/images/SESFTOOLS Signature.png"

  return (
    <Layout>
      <SEO title="Create your SESF signature" ogimage={`https://tools.sesf.ch${og}`} />
      <SignatureProvider>
        <Drawer />
        <Signature />
      </SignatureProvider>
    </Layout>
  )
}

export default IndexPage
