import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Signature from "./signature"

export const SignatureContext = React.createContext()

const SignatureProvider = ({ children }) => {
  const [signature, setSignature] = useState({
    firstname: "",
    lastname: "",
    role: "",
    department: "",
    entreprise: "",
    custom: "",
    phone: "",
    mobile: "",
    email: "",
    //adresse
    address1: "",
    address2: "",
    address3: "",
    address4: "",

    //social media profile
    linkedinUrl: "",
    facebookUrl: "",
    twitterUrl: "",
    instagramUrl: "",
    twitchUrl: "",
    onChange: () => {},
  })

  // load signature from local storage. Init if not present or error.
  const [contents, setContents] = useState(() => {
    let localSignature
    try {
      localSignature = JSON.parse(localStorage.getItem("signature"))
    } catch (e) {
      console.error(e.message)
    }
    if (!localSignature || !Array.isArray(localSignature)) return []
    return localSignature
  })

  function onChange(name, value) {
    setSignature((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
    localStorage.setItem(
      "signature",
      JSON.stringify({
        ...signature,
        [name]: value,
      })
    )
  }

  useEffect(() => {
    const storedSignature = JSON.parse(localStorage.getItem("signature"))
    if (storedSignature) {
      setSignature(storedSignature)
    }
  }, [])

  return (
    <SignatureContext.Provider value={{ ...signature, onChange }}>
      {children}
    </SignatureContext.Provider>
  )
}

SignatureProvider.propTypes = {}

export default SignatureProvider
