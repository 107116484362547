import React, { Component } from "react"
import PropTypes from "prop-types"
import "./help.scss"
import step1 from "../images/help/step-1.png"
import step2 from "../images/help/step-2.png"
import step3 from "../images/help/step-3.png"
import step4 from "../images/help/step-4.png"
import Modal from "./modal"

const Help = (props) => {
    return (
      <Modal closeModal={props.closeModal}>
        <h2>Install my signature</h2>
        <h3>In Gmail</h3>
        <p>In your gmail account page click on the cog icon in the top right</p>
        <img src={step1} alt="" className={'responsive-image'}/>
        <p>Then click on "Settings"</p>
        <img src={step2} alt="" className={'responsive-image'}/>
        <p>In the tab "General" scroll to find the "Signature" item and paste (Cmd+V or Ctrl+V) your signature in the box on the right</p>
        <img src={step3} alt="" className={'responsive-image'}/>
        <p>Save the changes</p>
        <img src={step4} alt="" className={'responsive-image'}/>
      </Modal>
    )
}

Help.propTypes = {}

export default Help
