import React, { useContext } from "react"

import SponsorSwisscom from "../../images/partners-logo/Swisscom_LOGO.png"
import CPLogo from "../../images/partners-logo/CP_LOGO.gif"
import AdfinasLogo from "../../images/partners-logo/Adfinas_LOGO.png"

import logo from "../../images/SESF_logo_squared.png"
import { SignatureContext } from "../SignatureProvider"
import { formatPhoneNumberIntl } from "react-phone-number-input"

const HorizontalSign = (props) => {
  const envelope = "/assets/images/png/iconmonstr-email-2-48.png"
  const link = "/assets/images/png/iconmonstr-link-1-48.png"
  const phoneHandset = "/assets/images/png/iconmonstr-phone-1-48.png"
  const adresseIcon = "/assets/images/png/iconmonstr-location-18-48.png"
  const linkedinIcon = "/assets/images/png/iconmonstr-linkedin-4-48.png"
  const facebookIcon = "/assets/images/png/iconmonstr-facebook-4-48.png"
  const twitterIcon = "/assets/images/png/iconmonstr-twitter-4-48.png"
  const instagramIcon = "/assets/images/png/iconmonstr-instagram-14-48.png"
  const twitchIcon = "/assets/images/png/iconmonstr-twitch-4-48.png"
  //sponsors & partners
  const SponsorWide = "/assets/images/wide_logo_rouge.png"
  const PartnerBTW = "/assets/images/BTW_Logo_SW_Quadrat.jpg"

  const signature = useContext(SignatureContext)

  const styleTable = {
    verticalAlign: "-webkit-baseline-middle",
    fontSize: "medium",
    fontFamily: "Arial",
  }

  const verticalAlignMiddle = {
    verticalAlign: "middle",
  }

  function formatToPhone(number) {
    return formatPhoneNumberIntl(number)
    let formatedNumber = number
    //const input = target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    const zip = number.substring(0, 3)
    const group1 = number.substring(3, 5)
    const group2 = number.substring(5, 8)
    const group3 = number.substring(8, 10)
    const group4 = number.substring(10, 12)

    if (number.length > 10) {
      formatedNumber = `(${zip}) ${group1} ${group2} ${group3} ${group4}`
    } else if (number.length > 8) {
      formatedNumber = `(${zip}) ${group1} ${group2} ${group3}`
    } else if (number.length > 5) {
      formatedNumber = `(${zip}) ${group1} ${group2}`
    } else if (number.length > 3) {
      formatedNumber = `(${zip}) ${group1}`
    } else if (number.length > 0) {
      formatedNumber = `(${zip}`
    }
    return formatedNumber
  }

  return (
    <table style={styleTable}>
      <tbody>
        <tr>
          <td>
            <table cellPadding="0" cellSpacing="0" style={styleTable}>
              <tbody>
                <tr>
                  <table>
                    <tbody>
                      <tr>
                        <td width="100" style={verticalAlignMiddle}>
                          <span
                            style={{ marginRight: "20px", display: "block" }}
                          >
                            <img
                              width="80"
                              src={logo}
                              role="presentation"
                              alt={"presentation"}
                            />
                          </span>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <h3
                            color="#000000"
                            style={{
                              margin: "0px",
                              fontSize: "18px",
                              color: "rgb(0, 0, 0)",
                            }}
                          >
                            <span>
                              {signature.firstname !== ""
                                ? signature.firstname
                                : "John"}
                            </span>
                            <span>&nbsp;</span>
                            <span>
                              {signature.lastname !== ""
                                ? signature.lastname
                                : "Doe"}
                            </span>
                          </h3>
                          <p
                            color="#000000"
                            fontSize="medium"
                            style={{
                              margin: "0px",
                              color: "rgb(0, 0, 0)",
                              fontSize: "14px",
                              lineHeight: "22px",
                            }}
                          >
                            <span>
                              {signature.role !== "" ? signature.role : "Role"}
                            </span>
                          </p>
                          <p
                            color="#000000"
                            fontSize="medium"
                            style={{
                              margin: "0px",
                              fontWeight: "500",
                              color: "rgb(0, 0, 0)",
                              fontSize: "14px",
                              lineHeight: "22px",
                            }}
                          >
                            <span>
                              {signature.department !== ""
                                ? signature.department
                                : "your department"}
                            </span>
                            {/*<span>&nbsp;|&nbsp;</span><span>SESF</span>*/}
                          </p>
                          <p
                            color="#000000"
                            fontSize="medium"
                            style={{
                              color: "rgb(0, 0, 0)",
                              margin: "0px",
                              fontSize: "14px",
                              lineHeight: "22px",
                            }}
                          >
                            <span>Swiss Esports Federation</span>
                          </p>
                        </td>
                        <td width="15">
                          <div style={{ width: "15px" }} />
                        </td>
                        <td
                          width="1"
                          color="#F2547D"
                          direction="vertical"
                          style={{
                            width: "1px",
                            borderBottom: "none",
                            borderLeft: "1px solid rgb(219, 33, 64)",
                          }}
                        />
                        <td width="15">
                          <div style={{ width: "15px" }} />
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <table
                            cellPadding="0"
                            cellSpacing="0"
                            style={{
                              verticalAlign: "-webkit-baseline-middle",
                              fontSize: "medium",
                              fontFamily: "Arial",
                            }}
                          >
                            <tbody>
                              {(signature.phone !== "" ||
                                signature.mobile !== "") && (
                                <tr
                                  height="25"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <td
                                    width="30"
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      style={{
                                        verticalAlign:
                                          "-webkit-baseline-middle",
                                        fontSize: "medium",
                                        fontFamily: "Arial",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ verticalAlign: "bottom" }}
                                          >
                                            <span
                                              width="11"
                                              color="#F2547D"
                                              style={{ display: "block" }}
                                            >
                                              <img
                                                height={15}
                                                src={phoneHandset}
                                                color="#F2547D"
                                                style={{ display: "block" }}
                                                alt={""}
                                              />
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td
                                    style={{
                                      padding: "0px",
                                      color: "rgb(0, 0, 0)",
                                    }}
                                  >
                                    <a
                                      href={"tel:" + signature.phone}
                                      color="#000000"
                                      style={{
                                        textDecoration: "none",
                                        color: "rgb(0, 0, 0)",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <span>
                                        {formatToPhone(signature.phone)}
                                      </span>
                                    </a>
                                    {signature.mobile &&
                                      signature.phone &&
                                      " | "}
                                    <a
                                      href={"tel:" + signature.mobile}
                                      color="#000000"
                                      style={{
                                        textDecoration: "none",
                                        color: "rgb(0, 0, 0)",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <span>
                                        {formatToPhone(signature.mobile)}
                                      </span>
                                    </a>
                                  </td>
                                </tr>
                              )}
                              <tr
                                height="25"
                                style={{ verticalAlign: "middle" }}
                              >
                                <td
                                  width="30"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <table
                                    cellPadding="0"
                                    cellSpacing="0"
                                    style={{
                                      verticalAlign: "-webkit-baseline-middle",
                                      fontSize: "medium",
                                      fontFamily: "Arial",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td style={{ verticalAlign: "bottom" }}>
                                          <span
                                            width="11"
                                            color="#F2547D"
                                            style={{ display: "block" }}
                                          >
                                            <img
                                              height={15}
                                              src={envelope}
                                              color="#F2547D"
                                              style={{ display: "block" }}
                                              alt={""}
                                            />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td style={{ padding: "0px" }}>
                                  <a
                                    href="mailto:"
                                    color="#000000"
                                    style={{
                                      textDecoration: "none",
                                      color: "rgb(0, 0, 0)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <span>
                                      {signature.email !== ""
                                        ? signature.email
                                        : "email@sesf.ch"}
                                    </span>
                                  </a>
                                </td>
                              </tr>
                              <tr
                                height="25"
                                style={{ verticalAlign: "middle" }}
                              >
                                <td
                                  width="30"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <table
                                    cellPadding="0"
                                    cellSpacing="0"
                                    style={{
                                      verticalAlign: "-webkit-baseline-middle",
                                      fontSize: "medium",
                                      fontFamily: "Arial",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td style={{ verticalAlign: "bottom" }}>
                                          <span
                                            width="11"
                                            color="#F2547D"
                                            style={{ display: "block" }}
                                          >
                                            <img
                                              width={15}
                                              src={link}
                                              color="#F2547D"
                                              style={{ display: "block" }}
                                              alt={""}
                                            />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td style={{ padding: "0px" }}>
                                  <a
                                    href={"https://www.sesf.ch"}
                                    color="#000000"
                                    style={{
                                      textDecoration: "none",
                                      color: "rgb(0, 0, 0)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <span>https://www.sesf.ch</span>
                                  </a>
                                </td>
                              </tr>
                              {(signature.address1 ||
                                signature.address2 ||
                                signature.address3 ||
                                signature.address4) && (
                                <tr
                                  height="25"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <td
                                    width="30"
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      style={{
                                        verticalAlign:
                                          "-webkit-baseline-middle",
                                        fontSize: "medium",
                                        fontFamily: "Arial",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{ verticalAlign: "bottom" }}
                                          >
                                            <span
                                              width="11"
                                              color="#F2547D"
                                              style={{ display: "block" }}
                                            >
                                              <img
                                                width={15}
                                                src={adresseIcon}
                                                color="#F2547D"
                                                style={{ display: "block" }}
                                                alt={""}
                                              />
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  {(signature.address1 ||
                                    signature.address2 ||
                                    signature.address3 ||
                                    signature.address4) && (
                                    <td style={{ padding: "0px" }}>
                                      <span
                                        color="#000000"
                                        style={{
                                          fontSize: "12px",
                                          color: "rgb(0, 0, 0)",
                                        }}
                                      >
                                        <span>
                                          {signature.address1}
                                          {signature.address2 &&
                                            ", " + signature.address2}
                                          {signature.address3 &&
                                            ", " + signature.address3}
                                          {signature.address4 &&
                                            ", " + signature.address4}
                                        </span>
                                      </span>
                                    </td>
                                  )}
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </tr>
                <tr>
                  <td>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                        fontSize: "small",
                        fontFamily: "Verdana",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            height={15}
                            style={{
                              borderBottom: "1px solid rgb(219, 33, 64)",
                            }}
                          />
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr style={{ textAlign: "right" }}>
                  <td>
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                        fontSize: "small",
                        fontFamily: "Verdana",
                        display: "inline-block",
                      }}
                    >
                      <tbody>
                        <tr height={"15"} />
                        <tr>
                          {(signature.linkedinUrl ||
                            signature.facebookUrl ||
                            signature.instagramUrl ||
                            signature.twitterUrl ||
                            signature.twitchUrl) && (
                            <td
                              style={{
                                textAlign: "center",
                                textTransform: "",
                                fontWeight: "bold",
                                color: "rgb(100, 100, 100)",
                              }}
                            >
                              Follow me
                            </td>
                          )}
                          {signature.linkedinUrl && (
                            <td width={30}>
                              <a href={signature.linkedinUrl}>
                                <img
                                  src={linkedinIcon}
                                  alt="my linkedin profile"
                                  height={24}
                                />
                              </a>
                            </td>
                          )}
                          {signature.facebookUrl && (
                            <td width={30}>
                              <a href={signature.facebookUrl}>
                                <img
                                  src={facebookIcon}
                                  alt="my facebook"
                                  height={24}
                                />
                              </a>
                            </td>
                          )}
                          {signature.twitterUrl && (
                            <td width={30}>
                              <a href={signature.twitterUrl}>
                                <img
                                  src={twitterIcon}
                                  alt="my twitter"
                                  height={24}
                                />
                              </a>
                            </td>
                          )}
                          {signature.instagramUrl && (
                            <td width={30}>
                              <a href={signature.instagramUrl}>
                                <img
                                  src={instagramIcon}
                                  alt="my instagram"
                                  height={24}
                                />
                              </a>
                            </td>
                          )}
                          {signature.twitchUrl && (
                            <td width={30}>
                              <a href={signature.twitchUrl}>
                                <img
                                  src={twitchIcon}
                                  alt="my twitch"
                                  height={24}
                                />
                              </a>
                            </td>
                          )}
                          <td width={30} />
                          <td
                            style={{
                              textAlign: "center",
                              textTransform: "",
                              fontWeight: "bold",
                              color: "rgb(100, 100, 100)",
                            }}
                          >
                            SESF
                          </td>
                          <td width={30}>
                            <a href="https://twitter.com/swissesports">
                              <img
                                src={twitterIcon}
                                alt="twitter sesf"
                                height={24}
                              />
                            </a>
                          </td>
                          <td width={30}>
                            <a href="https://ch.linkedin.com/company/swiss-esports-federation">
                              <img
                                src={linkedinIcon}
                                alt="linkedin sesf"
                                height={24}
                              />
                            </a>
                          </td>
                          <td width={30}>
                            <a href="https://www.facebook.com/swissesports">
                              <img
                                src={facebookIcon}
                                alt="facebook sesf"
                                height={24}
                              />
                            </a>
                          </td>
                        </tr>
                        {(signature.linkedinUrl ||
                          signature.facebookUrl ||
                          signature.instagramUrl ||
                          signature.twitterUrl ||
                          signature.twitchUrl) && <tr height={"10"} />}
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr style={{ textAlign: "right" }}>
                  <td>
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                        fontSize: "small",
                        fontFamily: "Verdana",
                        display: "inline-block",
                      }}
                    >
                      <tbody>
                        <tr height={15} />
                        <tr>
                          <td style={{ textAlign: "center" }}>
                            <a href="https://www.swisscom.ch/">
                              <img
                                src={SponsorSwisscom}
                                alt="Swissomm"
                                height={50}
                              />
                            </a>
                          </td>
                          <td width={15} />
                          <td>
                            <a href="https://adfinas.ch/">
                              <img
                                src={AdfinasLogo}
                                alt="Partner ADFINAS"
                                height={50}
                              />
                            </a>
                          </td>
                          <td width={15} />
                          <td>
                            <a href="https://www.centrepatronal.ch/">
                              <img
                                src={CPLogo}
                                alt="Partner Centre Patronal"
                                height={50}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr height={5} />
                        <tr style={{ verticalAlign: "top" }}>
                          <td
                            style={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              color: "rgb(100, 100, 100)",
                            }}
                          >
                            Supporter
                          </td>
                          <td width={15} />
                          <td
                            colSpan={4}
                            style={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              color: "rgb(100, 100, 100)",
                            }}
                          >
                            Partners
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default HorizontalSign
