import React, { useEffect, useState } from "react"
import "./signature.scss"
import logo from "../images/SESF_logo_squared.png"

// importer les templates
import HorizontalSign from "./templates/horizontalsign"
import VerticalSign from "./templates/verticalsign"
import Help from "./help"

const Signature = (props) => {
  const [component, setComponent] = useState("horizontal")
  const [buttonText, setButtonText] = useState(`Click to copy`)
  const [copy, setCopy] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [signIsOpen, setSignIsOpen] = useState(false)

  useEffect(() => {
    setSignIsOpen(true)
  }, [])

  const components = {
    horizontal: HorizontalSign,
    vertical: VerticalSign,
  }

  const showHelp = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setModalIsOpen((prevState) => !prevState)
    }
  }

  const performCopy = () => {
    console.log("copy")
    var selection = window.getSelection()
    var emailLink = document.querySelector("#copyThis")

    selection.removeAllRanges()
    var range = document.createRange()
    range.selectNode(emailLink)
    selection.addRange(range)

    try {
      var successful = document.execCommand("copy")
      var msg = successful ? "Copied" : "Something went wrong"
      setButtonText(msg)
      setCopy(true)
      //log('Copy email command was ' + msg);
      setTimeout(() => {
        setCopy(false)
      }, 2500)
    } catch (err) {
      //log('execCommand Error', err);
    }

    selection.removeAllRanges()
  }

  const TagName = components[props.tag || "horizontal"]

  return (
    <div className={"signature-preview"}>
      <div className="header-signature">
        <div className="header-signature__inner">
          <img src={logo} height={50} alt="" />
          <h1>SeSF - Tools</h1>
        </div>
      </div>
      <div className={`mail-window-wrapper ${signIsOpen && "open"}`}>
        <div className="top-mail-window">
          <div className="close-icon" />
          <div className="minimize-icon" />
          <div className="expand-icon" />
        </div>
        <div className="header-mail-window">
          <span className="destinataire">
            To: <span className={"destinataire-texte"}>your correspondant</span>
          </span>
          <span className="objet">
            Subject:{" "}
            <span className={"objet-texte"}>
              new sesf mail signature, duh !
            </span>
          </span>
        </div>
        <div className="navigateur-mail-window">
          <div className={"signature-wrapper"} id={"copyThis"}>
            <TagName />
          </div>
        </div>
      </div>
      <div className="copy">
        <button
          className={`btn btn-cta ${copy && "copied"}`}
          onClick={() => performCopy()}
        >
          {copy ? "Copied !" : "Copy to clipboard"}
        </button>
        <div>
          <p
            role={"button"}
            className={"help"}
            onClick={() => showHelp()}
            onKeyPress={(e) => handleKeyPress(e)}
          >
            How to install my signature ?
          </p>
        </div>
        <div id={"copyMe"} contentEditable={true} />
      </div>
      {modalIsOpen && <Help closeModal={() => closeModal()} />}
      <footer style={{ textAlign: "right", padding: "0 2rem" }}>
        Crafted with <span role={"img"}>❤️</span> by eidolon.design
      </footer>
    </div>
  )
}

export default Signature
