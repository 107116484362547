import React, { useContext, useEffect, useState } from "react"
import anime from "animejs"
import "./drawer.scss"
import { SignatureContext } from "./SignatureProvider"

const Drawer = (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  const signature = useContext(SignatureContext)

  useEffect(() => {
    setTimeout(() => {
      setDrawerIsOpen(true)
    }, 0)
    setTimeout(() => {
      anime({
        targets: ".drawer > div",
        translateX: -70,
        opacity: 0,
        direction: "reverse",
        delay: anime.stagger(100, { direction: "reverse" }), // increase delay by 100ms for each elements.
      })
    }, 0)
  }, [])

  const handleChange = (e) => {
    signature.onChange(e.target.name, e.target.value)
  }

  return (
    <div className={`drawer ${drawerIsOpen && "open"}`}>
      <div className={"titre-informations"}>
        <h2>Enter your signature details</h2>
      </div>
      <div className={"drawer-input"}>
        Name :
        <input
          name={"firstname"}
          placeholder={"John"}
          type="text"
          value={signature.firstname}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className={"drawer-input"}>
        LastName :
        <input
          name={"lastname"}
          placeholder={"Doe"}
          type="text"
          value={signature.lastname}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="drawer-input">
        Role :
        <input
          name={"role"}
          placeholder={"Marketer"}
          type="text"
          value={signature.role}
          onChange={handleChange}
        />
      </div>
      <div className="drawer-input">
        Department :
        <input
          name={"department"}
          placeholder={"ex: Communication"}
          type="text"
          value={signature.department}
          onChange={handleChange}
        />
      </div>
      {/*<div className="drawer-input">
        Entreprise :
        <input
          name={"entreprise"}
          placeholder={"Entreprise"}
          type="text"
          value={signature.entreprise}
          onChange={handleChange}
        />
      </div>*/}
      {/*<div className="drawer-input">
        Custom info :
        <input
          type="text"
          name={"custom"}
          placeholder={"Custom"}
          value={signature.custom}
          onChange={handleChange}
        />
      </div>*/}

      <div className="drawer-input">
        Phone (optionnal):
        <input
          type="text"
          name={"phone"}
          placeholder={"+41 XXX XXX XXX"}
          value={signature.phone}
          maxLength={15}
          onChange={handleChange}
        />
      </div>
      <div className="drawer-input">
        Mobile (optionnal):
        <input
          type="text"
          name={"mobile"}
          placeholder={"+41 XXX XXX XXX"}
          value={signature.mobile}
          maxLength={15}
          onChange={handleChange}
        />
      </div>
      <div className="drawer-input">
        Email :
        <input
          type="email"
          name={"email"}
          placeholder={"john.doe@sesf.ch"}
          value={signature.email}
          onChange={handleChange}
        />
      </div>
      <div className="drawer-input">
        Address (optionnal):
        <input
          type="text"
          name={"address1"}
          placeholder={"Line 1"}
          value={signature.address1}
          onChange={handleChange}
        />
        <input
          type="text"
          name={"address2"}
          placeholder={"Line 2"}
          value={signature.address2}
          onChange={handleChange}
        />
        <input
          type="text"
          name={"address3"}
          placeholder={"Line 3"}
          value={signature.address3}
          onChange={handleChange}
        />
        <input
          type="text"
          name={"address4"}
          placeholder={"Line 4"}
          value={signature.address4}
          onChange={handleChange}
        />
      </div>

      <div className="titre-informations">
        <h2>Your Social Links</h2>
        <span>(optionnal)</span>
      </div>
      <div className="drawer-input">
        linkedin :
        <input
          name={"linkedinUrl"}
          type="url"
          placeholder={"https://www.linkedin.com"}
          value={signature.linkedinUrl}
          onChange={handleChange}
        />
      </div>
      <div className="drawer-input">
        facebook :
        <input
          name={"facebookUrl"}
          type="url"
          placeholder={"https://www.facebook.com"}
          value={signature.facebookUrl}
          onChange={handleChange}
        />
      </div>
      <div className="drawer-input">
        Twitter :
        <input
          name={"twitterUrl"}
          type="url"
          placeholder={"https://www.twitter.com"}
          value={signature.twitterUrl}
          onChange={handleChange}
        />
      </div>
      <div className="drawer-input">
        Twitch :
        <input
          name={"twitchUrl"}
          type="url"
          placeholder={"https://www.twitch.com/user"}
          value={signature.twitchUrl}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
export default Drawer
